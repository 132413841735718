@font-face {
  font-family: "AR BONNIE";
  src: url("../public/fonts/arbonnie.woff") format("woff");
  font-display: swap;
}

$primary: #00a199;

@import "~bootstrap/scss/bootstrap";

.boni {
  font-family: "AR BONNIE", sans-serif !important;
}


.btn {
  border-radius: 0;
}

.btn-green {
  border: 0;
}

.bg-green {
  background-color: #00a199;
}

.uia_green {
  color: #00a199;
}

.uia_yellow {
  color: #fae811;
}

.Brand {
  img {
    height: 100px;
    @media (max-width: 770px) {
      height: 65px;
    }
    @media (max-width: 576px) {
      height: 38px;
    }
  }
}

.banner {
  background: url('../public/images/249.jpg') no-repeat center center;
  background-size: cover;
  min-height: 50vw;
  background-position: top !important;

  color: white;

  .text {
    background: #00a19980;
  }
}


.offers {
  height: 150px;
  background-size: cover !important;
  vertical-align: middle;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &.uno {
    background: url('../public/images/52.jpg') no-repeat center center;
  }

  &.dos {
    background: url('../public/images/243936.jpg') no-repeat center center;
  }

  &.tres {
    background: url('../public/images/1491.jpg') no-repeat center center;
  }

  &.cuatro {
    background: url('../public/images/6305.jpg') no-repeat center center;
  }

  h5 {
    background: #00a19980;
    width: 70%;
  }
}

section {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  padding-right: 0;
}

.contacto {
  input, textarea {
    border-radius: unset;
  }
}

.direccion {
  a {
    color: white;
  }
}

#Header {
  background: rgba(255,255,255,0.9);
  backdrop-filter: blur(3px);
}

a {
  text-decoration: none;
}